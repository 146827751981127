import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

let containerWidth = window.innerWidth;

if (window.innerWidth > 1550) {
    containerWidth = window.innerWidth * 0.3;
} else if (window.innerWidth > 700) {
    containerWidth = window.innerWidth * 0.5;
}

ReactDOM.render(<App container={containerWidth}/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
