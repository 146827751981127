import React from "react";

/**
 * Target Component to resemble a Target to go to
 */
export class Target extends React.Component {
    render(){
        return (
            <div className="target" style={{width: this.props.container/6*2, height: this.props.container/6, left: this.props.container/6*4, top: this.props.container/6*2}}>Ziel</div>
        )
    }
}