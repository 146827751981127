import React from 'react';
import {Block} from "./Block";
import { Target } from "./Target";
import { Counter } from "./Count";
import { Winner } from "./Winner";
import ReactGA from "react-ga";



export class Grid extends React.Component {
    constructor(props){
        super(props);
        this.cell = (props.container/6); //the cell dimension is 1/6 of the whole container
        this.state = {
            blocks: props.blocks,
            count: 0,
            won: false
        };
        this.maxPos = 6;
        this.minPos = 1;

        this.setPosition = this.setPosition.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.changeClass = this.changeClass.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleReset = this.handleReset.bind(this);
    }

    /**
     * Setting all the Event-Listeners
     */
    componentDidMount() {
        window.addEventListener('mousemove', this.handleMouseMove);
        window.addEventListener('touchmove', this.handleMouseMove)
        window.addEventListener('mouseup', this.handleMouseUp);
        window.addEventListener('touchend', this.handleMouseUp);
    }

    /**
     * removing Event-Listeners after component did unmount
     */
    componentWillUnmount() {
        window.removeEventListener('mousemove', this.handleMouseMove);
        window.removeEventListener('mouseup', this.handleMouseUp);
        window.removeEventListener('touchmove', this.handleMouseMove);
        window.removeEventListener('touchend', this.handleMouseUp);
    }

    /**
     * Handles mouse moves. Looks for the Element that is being dragged and then calls @link{setPosition} to set the new Position.
     * @param e Event variable
     */
    handleMouseMove(e) {
        let newPos;
        const oldBlocks = this.state.blocks;
        let mousePosX;
        let mousePosY;
        //if function was called from a touch, set mousePos to touch coordinates
        if (e.touches) {
            mousePosX = e.touches[0].clientX;
            mousePosY = e.touches[0].clientY;
        } else {
            mousePosX = e.clientX;
            mousePosY = e.clientY;
        }

        //go through all the blocks and detect the one that's being dragged.
        if (oldBlocks[this.state.dragId]) {
            if (oldBlocks[this.state.dragId].width) {
                newPos = ((mousePosX - this.mouseOffset) / this.cell) + 1; //calculate the new Position. is divided by cell + 1 to get grid coordinates.
                this.setPosition(this.state.dragId, newPos, 0);
            } else if (oldBlocks[this.state.dragId].height) {
                newPos = ((mousePosY - this.mouseOffset) / this.cell) + 1; //have to divide by cell because we want to keep it simple at app.js and don't work with pixel values
                this.setPosition(this.state.dragId, newPos, 1);
            }
        }

    }

    /**
     * set State to new position if new Position is in range.
     * @param blockIndex index of the block that get's a new position
     * @param newPos new Position for the block
     * @param c orientation. 0 means the block is wide, 1 means high
     */
    setPosition(blockIndex, newPos, c) {
        const oldBlocks = this.state.blocks;
        const extension = c === 1 ? oldBlocks[blockIndex].height : oldBlocks[blockIndex].width;

        if (newPos > this.minPos && newPos < (this.maxPos - (extension - 1))) { //check if newPos is within range
            oldBlocks[blockIndex].position[c] = newPos;
        } else if (newPos > (this.maxPos - (extension - 1))) { //check if newPos is bigger then maximum position. if so, set it to maximum position.
            oldBlocks[blockIndex].position[c] = (this.maxPos - (extension - 1));
        } else if (newPos < this.minPos) { //same for minimum
            oldBlocks[blockIndex].position[c] = (this.minPos);
        }

        this.setState({blocks: oldBlocks});
    }

    /**
     * Event Handler for the mouseUp Event. Makes blocks snap into the grid.
     * @param e Event Object. is never used.
     */
    handleMouseUp (e) {
        let won = false;
        const oldBlocks = this.state.blocks;

        //check if a block is being dragged
        if (oldBlocks[this.state.dragId]) {
            if (oldBlocks[this.state.dragId].width) {
                oldBlocks[this.state.dragId].position[0] = Math.round(oldBlocks[this.state.dragId].position[0]);
            }

            if (oldBlocks[this.state.dragId].height) {
                oldBlocks[this.state.dragId].position[1] = Math.round(oldBlocks[this.state.dragId].position[1]);
            }

            oldBlocks[this.state.dragId].dragging = false;
            const oldCount = this.state.count;

            if (oldBlocks[this.state.dragId].position[1] === 3 && (oldBlocks[this.state.dragId].position[0] + (oldBlocks[this.state.dragId].width - 1) === 6)){ //check for win conditions
                won = true;
            }
            this.setState({blocks: oldBlocks, count: oldCount+1, won: won, dragId: false});
        }
    }

    /**
     * mouseMove Handler is called to call the minMax function and set the dragged block
     * @param id of the clicked block
     * @param offset from mouse to coordinate of block
     */
    changeClass(id, offset) {
        const oldBlocks = this.state.blocks;
        oldBlocks[id].dragging = true;
        this.mouseOffset = offset;
        this.setMinMax(oldBlocks, id);
        this.setState({blocks: oldBlocks, dragId: id});
    }

    /**
     * Function to calculate the minimal and maximal Position for given block.
     * @param oldBlocks Array of all the blocks
     * @param id integer of the target block
     */
    setMinMax(oldBlocks, id) {
        const c = oldBlocks[id].height ? 1 : 0;
        const targetBlock = oldBlocks[id].position[c];
        const extensionTarget = oldBlocks[id].height ? oldBlocks[id].height : oldBlocks[id].width;
        this.maxPos = 6;
        this.minPos = 1;
        let i;
        for (i = 0; i < oldBlocks.length; i++) {
            if (i !== id) {
                const otherBlock = oldBlocks[i].position[c];
                const otherBlockNeg = oldBlocks[i].position[((c - 1) * -1)];
                const targetBlockNeg = oldBlocks[id].position[((c - 1) * -1)];

                //define extension in negative direction
                let extensionOther;
                if (c === 1) {
                    extensionOther = oldBlocks[i].width ? oldBlocks[i].width : 0;
                } else {
                    extensionOther = oldBlocks[i].height ? oldBlocks[i].height : 0;
                }

                if (targetBlockNeg === otherBlockNeg){
                    if (otherBlock >= (targetBlock + extensionTarget) && (otherBlock - 1) < this.maxPos) {
                        this.maxPos = (otherBlock - 1);
                    } else if (otherBlock < targetBlock && (otherBlock + 1) > this.minPos ) {
                        if (c === 1) {
                            extensionOther = oldBlocks[i].height ? oldBlocks[i].height : 1;
                        } else {
                            extensionOther = oldBlocks[i].width ? oldBlocks[i].width : 1;
                        }
                        this.minPos = otherBlock + extensionOther;
                    }

                } else if (otherBlockNeg < targetBlockNeg && (otherBlockNeg + extensionOther) > targetBlockNeg) {
                    if (otherBlock >= (targetBlock + extensionTarget) && (otherBlock - 1) < this.maxPos) {
                        this.maxPos = (otherBlock - 1);
                    } else if (otherBlock < targetBlock && (otherBlock + 1) > this.minPos ) {
                        this.minPos = otherBlock + 1;
                    }
                }
            }
        }
    }

    /**
     * function to create Blocks Components
     * @param blocksArray Array of objects to make blocks from
     * @returns {[]} returns an Array of Block Components
     */
    createBlocks(blocksArray) {
        let i;
        let blocksComp = [];

        for ( i = 0; i < blocksArray.length; i++ ) {
            let width = this.cell;
            let height = this.cell;
            let position = [];

            //Set Width
            if (blocksArray[i].width > 1) {
                width = this.cell * blocksArray[i].width;
            }

            //Set Height
            if (blocksArray[i].height > 1) {
                height = this.cell * blocksArray[i].height;
            }

            //Set x and y coordinates in px
            position[0] = this.cell * (blocksArray[i].position[0] - 1);
            position[1] = this.cell * (blocksArray[i].position[1] - 1);
            blocksComp.push(<Block width={width} height={height} position={position} key={i} id={i} colors={this.props.colors} tags={this.props.tags}  onMouseDown={this.changeClass} class={blocksArray[i].class ? blocksArray[i].class : ''} />);
        }
        return blocksComp;
    }

    /**
     * Simple function to return a Winner Component if the game is won
     * @param won boolean
     * @returns {*} Winner Component
     */
    winner (won) {
        if (won) {
            return <Winner />;
        }
    }

    render(){
        return (
            <div className="grid">
                {this.winner(this.state.won)}
                <Counter count={this.state.count} minMoves={this.props.minMoves}/>
                <div className="board">
                    {this.createBlocks(this.state.blocks)}
                    <Target container={this.props.container} />
                </div>
                <button onClick={this.handleReset}>Zurücksetzen</button>
            </div>
        )
    }

    handleReset() {
        ReactGA.event({
                category: 'User',
                action: 'reset'
            })
        //TODO: this is really not the way it should work, but somehow changes in the state of Grid also change it's props! and more than that, actually change the variable that I've given the prop! I got something very wrong...
        this.setState({blocks: [{
                class: "red",
                width: 2,
                position: [1,3]}, {
                height: 2,
                position: [1,1]
            }, {
                width: 2,
                position: [2,1]
            }, {
                height: 2,
                position: [5,1]
            }, {
                height: 3,
                position: [3,2]
            }, {
                height: 2,
                position: [6,2]
            }, {
                width: 2,
                position: [1,4]
            }, {
                width: 2,
                position: [4,4]
            }, {
                height: 2,
                position: [6,4]
            }, {
                width: 3,
                position: [1,5]
            }, {
                height: 2,
                position: [4,5]
            }, {
                width: 2,
                position: [2,6]
            }],
            count: 0,
            won:false});

    }
}