import React from "react";

/**
 * Counter Component shows current count and minimal count
 */
export class Counter extends React.Component {
    render(){
        return (
            <div className="counter">
                <div className="count" >
                    <p>Züge</p>
                    <p>{this.props.count}</p>
                </div>
                <div className="minMoves" >
                    <p>Minimum</p>
                    <p>{this.props.minMoves}</p>
                </div>
            </div>
        )
    }
}