import React from 'react';
import './App.css';
import { Grid } from './Grid';
import logo from './logo.png';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-143875146-2');
ReactGA.pageview(window.location.pathname + window.location.search);


function App(props) {
  //configuration of blocks
  const config = [{
    class: "red",
    width: 2,
  position: [1,3]}, {
    height: 2,
    position: [1,1]
  }, {
    width: 2,
    position: [2,1]
  }, {
    height: 2,
    position: [5,1]
  }, {
    height: 3,
    position: [3,2]
  }, {
    height: 2,
    position: [6,2]
  }, {
    width: 2,
    position: [1,4]
  }, {
    width: 2,
    position: [4,4]
  }, {
    height: 2,
    position: [6,4]
  }, {
    width: 3,
    position: [1,5]
  }, {
    height: 2,
    position: [4,5]
  }, {
    width: 2,
    position: [2,6]
  }];
  //available colors for the blocks
  const colors = ["#668fff", "#52ab6e", "#ffde69"];
  //available tags for the blocks
  const tags = ["E-Mail", "Virenschutz", "Passwörter", "Backup", "Website", "WLAN", "IoT", "Word", "Excel", "Photoshop", "Datenbank", "E-Banking", "Powerline", "Drucker", "Zip", "Https://", "Cloud"];

  return (
  <div className="App" id="fixed">
    <a
        className="App-logo"
        href="https://flicks.jetzt"
        target="_blank"
        rel="noopener noreferrer"
        onClick={ReactGA.event({
          category: 'User',
          action: 'click to flicks'
        })}
    ><img src={logo} alt="logo" /></a>
    <header className="App-header">

      <h2>Können Sie Ihre IT-Probleme lösen?</h2>
      <p>Bringen Sie Ihren Computer (links) in den gewünschten Zustand (rechts). Verschieben Sie dazu die IT-Aufgaben um ans Ziel zu kommen.</p>
    </header>
      <Grid className="app" blocks={config} minMoves="14" colors={colors} tags={tags} container={props.container} />

    <div className="App-header">
    <h2>Täglich sind wir mit digitalen Themen konfrontiert, die uns überfordern können. Manchmal fühlt es sich an, als hätte man ein grosses Puzzle vor sich, dabei soll einem die Technologie doch ein Werkzeug sein!</h2>
      <a
          className="button"
          href="https://flicks.jetzt"
          target="_blank"
          rel="noopener noreferrer"
          onClick={ReactGA.event({
            category: 'User',
            action: 'click to flicks'
          })}
      >
        Flicks.jetzt hilft Ihnen gerne!
      </a>
    </div>
  </div>
  );
}

export default App;
