import React from 'react';


function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min +1)) + min;
}

export class Block extends React.Component {
    constructor(props){
        super(props);
        this.bgColor = this.props.colors[getRandomInt(0,this.props.colors.length - 1)];
        this.tag = this.props.tags[getRandomInt(0,this.props.tags.length - 1)];
        this.rotate = this.props.width < this.props.height;
        this.handleMouseDown = this.handleMouseDown.bind(this);
    }

    /**
     * Handler for Mouse and Touch events. Sets the offset of mouse and coordinate and calls the Handler of the parent element
     * @param e Event Object
     */
    handleMouseDown (e) {
        let offset = 0;
        const mousePosX = e.clientX ? e.clientX : e.touches[0].clientX;
        const mousePosY = e.clientY ? e.clientY : e.touches[0].clientY;

        if (this.props.width > this.props.height){
            offset = mousePosX - this.props.position[0];
        } else {
            offset = mousePosY - this.props.position[1];
        }
        this.props.onMouseDown(this.props.id, offset);
    }

    render(){
        const cssClass = `block ${this.props.class}`;
        const cssStyle = {
            left: this.props.position[0],
            top: this.props.position[1],
            width: this.props.width,
            height: this.props.height,
            backgroundColor: this.props.class ? this.props.class : this.bgColor
        };
        return (
            <div className={ cssClass } style={ cssStyle} onMouseDown={this.handleMouseDown} onTouchStart={this.handleMouseDown} ><div className={this.rotate ? "rotate" : ""}>{this.props.class ? "Ihr Computer" : this.tag}</div></div>
        )
    }
}