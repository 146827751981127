import React from "react";
import ReactGA from "react-ga";

/**
 * Winner Component has no functionality but to show information.
 */
export class Winner extends React.Component {
    render(){
        return (
            <div className="winner">
                <div className="congrats">
                    <p>Herzliche Gratulation! Sie konnten Ihre IT-Probleme lösen! Für ernstere Probleme melden Sie sich gerne bei mir via <a href="mailto: flyer@flicks.jetzt">Email</a> oder per <a href="tel: +41 78 334 62 47">Telefon</a>.</p>
                    <a
                        className="button"
                        rel="noopener noreferrer"
                        href="https://flicks.jetzt"
                        target="_blank"
                        onClick={ReactGA.event({
                            category: 'User',
                            action: 'click to flicks - winner'
                        })}>Mehr Infos</a>
                </div>
            </div>
        )
    }
}